<template>
  <div
    ref="dropdownContainer"
    class="dropdown-container flex"
    @click="toggleDropdown"
  >
    <div class="flex">
      <div class="calendar">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
          id="calendar"
          size="50"
          width="20px"
        >
          <g fill="#134563">
            <path
              d="M49.6 54H14.4C12 54 10 52 10 49.6V17.3c0-2.4 1.6-4.4 3.7-4.4h2.2v2.9h-2.2c-.3 0-.7.6-.7 1.5v32.3c0 .8.7 1.5 1.5 1.5h35.2c.8 0 1.5-.7 1.5-1.5V17.3c0-.9-.5-1.5-.7-1.5h-2.2v-2.9h2.2c2 0 3.7 2 3.7 4.4v32.3C54 52 52 54 49.6 54"
            ></path>
            <path
              d="M20.3 18.8c-.8 0-1.5-.7-1.5-1.5v-5.9c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v5.9c-.1.8-.7 1.5-1.5 1.5m23.4 0c-.8 0-1.5-.7-1.5-1.5v-5.9c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v5.9c0 .8-.7 1.5-1.5 1.5M24.7 12.9h14.6v3H24.7zM12.9 21.7h38.2v3H12.9zM45.2 27.6h2.9v2.9h-2.9zM39.3 27.6h3v2.9h-3zM33.5 27.6h2.9v2.9h-2.9zM27.6 27.6h2.9v2.9h-2.9zM21.7 27.6h3v2.9h-3zM45.2 33.5h2.9v2.9h-2.9zM39.3 33.5h3v2.9h-3zM33.5 33.5h2.9v2.9h-2.9zM27.6 33.5h2.9v2.9h-2.9zM21.7 33.5h3v2.9h-3zM15.9 33.5h2.9v2.9h-2.9zM45.2 39.3h2.9v3h-2.9zM39.3 39.3h3v3h-3zM33.5 39.3h2.9v3h-2.9zM27.6 39.3h2.9v3h-2.9zM21.7 39.3h3v3h-3zM15.9 39.3h2.9v3h-2.9zM39.3 45.2h3v2.9h-3zM33.5 45.2h2.9v2.9h-2.9zM27.6 45.2h2.9v2.9h-2.9zM21.7 45.2h3v2.9h-3zM15.9 45.2h2.9v2.9h-2.9z"
            ></path>
          </g>
        </svg>
      </div>
      <!-- <div class="dropdown-selected">
        {{ selectedOption ? selectedOption.name : placeholder }}
      </div> -->
      <div class="dropdown-selected">
    <!-- Display the placeholder dynamically -->
    <!-- {{ customDate }} -->
    {{ selectedOption && selectedOption.name !== 'Custom Date' 
      ? selectedOption.name 
      : customDate || placeholder }}
  </div>
    </div>
    <div class="calendar-1">
      <svg
        fill="#ccc"
        width="15px"
        height="15px"
        viewBox="-6.5 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>dropdown</title>
        <path
          d="M18.813 11.406l-7.906 9.906c-0.75 0.906-1.906 0.906-2.625 0l-7.906-9.906c-0.75-0.938-0.375-1.656 0.781-1.656h16.875c1.188 0 1.531 0.719 0.781 1.656z"
        ></path>
      </svg>
    </div>
    <div v-if="isOpen" class="dropdown-options bg-white dark:bg-slate-900">
      <div
        v-for="dateOption in dateOptions"
        :key="dateOption.id"
        class="dropdown-option"
        :class="{ 'selected-option': selectedOption && selectedOption.id === dateOption.id }"
        @click="selectOption(dateOption)"
      >
        {{ dateOption.name }}
      </div>
    </div>
  </div>
</template>
    
    <script>
export default {
  props: {
    customDate: {
      type: String,
      default: '', // Initialize with an empty string
    },

    dateOptions: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Select a date range',
    },
    addMoreLabel: {
      type: String,
      default: 'Add More',
    },
    open: {
      type: Boolean,
      required: false,
    },
  },
  watch:{
    open(newVal){
      this.isOpen=newVal;
    }
  },
  data() {
    return {
      isOpen: false,
      selectedOption: null,
    };
  },
  methods: {
    toggleDropdown(event) {
      event.stopPropagation(); // Prevents toggleDropdown from closing immediately on open
      // this.isOpen = !this.isOpen;
      this.$emit("toggle");
    },
    selectOption(option) {
      console.log('Hello', option);
      this.selectedOption = option;
      this.isOpen = false;
      this.$emit('input', option.value);
      this.closeDropdown();
    },
    closeDropdown() {
      this.isOpen = false;
    },
    handleOutsideClick(event) {
      if (this.isOpen && !this.$refs.dropdownContainer.contains(event.target)) {
        this.closeDropdown();
      }
    },
    setDropdownNull(data){
      console.log("Data:::",data);
      this.selectedOption=null
    },
    resetDropdown() {
      this.selectedOption = null; // Clear selected option
      this.$emit('update:customDate', ''); // Reset custom date
    },
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
    bus.$on('dropdown-data', this.setDropdownNull);
    bus.$on('reset-date-dropdown', this.resetDropdown); // Listen for reset event
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
    bus.$off('reset-date-dropdown', this.resetDropdown); // Cleanup event listener
  },
};
</script>
    <style scoped>
.dropdown-container {
  position: relative;
  justify-content: space-between;
  min-width: 170px;
  /* width:267px; */
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 5px;
}

.dropdown-selected {
  font-size: 14px;
  /* color: #989898; */
  padding: 0px 11px;
}

.dropdown-options {
  position: absolute;
  box-shadow: 0px 3px 6px #00000029;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  z-index: 1;
  max-height: 168px;
  overflow: auto;
  font-family: 'Pacaembu Trial Light';
}
.dropdown-options::-webkit-scrollbar {
  display: none;
}

.dropdown-option {
  padding: 10px 20px;
  font-size: 14px;
  color: grey;
  cursor: pointer;
  transition: background-color 0.2s;
  border-bottom: 1px solid #fff5f5;
}

.dropdown-option:hover {
  color: #f0f0f0;
  background-color: #007bff;
}

.add-more-option {
  /* color: #b1b1b1; */
  color: grey;
}

.dropdown-option:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.dropdown-option:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.selected-option {
  background-color: #007bff !important; /* Adjust color as needed */
  color: #fff;
}
.calendar-1 {
  margin-top: 3px;
}
</style>
 