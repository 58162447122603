<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="h-auto overflow-auto mt-3 px-8 pb-2">
      <woot-modal-header> </woot-modal-header>
      <div class="bg-slate-200 custon_tab text-black-900" @click="toggleElement('import')">
        {{ $t('IMPORT_CONTACTS.TITLE') }}
      </div>
      <div class="bg-slate-200 custon_tab text-black-900" @click="toggleElement('sync')">
        {{ $t('Sync Fb Contact') }}
      </div>
    </div>
    <div
      class="h-auto overflow-auto flex flex-col import_contact"
      v-show="showImport"
    >
      <div class="flex flex-col p-8 pt-0">
        <p>
          {{ $t('IMPORT_CONTACTS.DESC') }}
          <a :href="csvUrl" download="import-contacts-sample">{{
            $t('IMPORT_CONTACTS.DOWNLOAD_LABEL')
          }}</a>
        </p>
        <div class="w-full">
          <label>
            <span
              >
              {{ $t('IMPORT_CONTACTS.FORM.LABEL') }}</span>
            <input
              id="file"
              ref="file"
              type="file"
              accept="text/csv"
              @change="handleFileUpload"
            />
          </label>
        </div>
        <div class="flex gap-3">
          <input type="checkbox" v-model="inboxDropdownEnabled" />
          <div class="assign-title">
            Assign Inbox and agents
          </div>
        </div>
        <select v-if="inboxDropdownEnabled" v-model="selectedInbox" @change="fetchAttachedAgents">
          <option disabled value="">Select Inbox</option>
          <option v-for="inbox in inboxes" :key="inbox.id" :value="inbox.id">{{ inbox.name }}</option>
        </select>
        <multiselect
          v-if="inboxDropdownEnabled"
          v-model="selectedAgents"
          :options="selectedAgent"
          track-by="id"
          label="name"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :hide-selected="true"
          placeholder="Pick agents"
          selected-label
          :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
          :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
        />
        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
          <div class="w-full">
            <woot-button
              :disabled="uiFlags.isCreating || !file"
              :loading="uiFlags.isCreating"
              @click="uploadFile"
            >
              {{ $t('IMPORT_CONTACTS.FORM.SUBMIT') }}
            </woot-button>
            <button class="button clear" @click.prevent="onClose">
              {{ $t('IMPORT_CONTACTS.FORM.CANCEL') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="h-auto overflow-auto flex flex-col sync_contact"
      v-show="showSync"
    >
      <div class="flex flex-col pt-0 pb-8 pl-8 pr-8">
        <p>
          Sync Facebook lead to contact
        </p>
        <div class="w-[320px] h-[80px]">
          <label>
            <span> <p>Select Date</p></span>

            <div class="date-picker">
              <date-picker
                :range="true"
                :confirm="true"
                :clearable="false"
                :editable="false"
                :confirm-text="confirmText"
                :placeholder="placeholder"
                :value="value"
                @change="handleChange"
                v-model="selectedDate"
              />
            </div>
          </label>
        </div>
        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
          <div class="w-full">
            <woot-button @click="sync_contact">
              Submit
            </woot-button>
            <button class="button clear" @click.prevent="onClose">
              {{ $t('IMPORT_CONTACTS.FORM.CANCEL') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '../../../../components/Modal';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { CONTACTS_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import DatePicker from 'vue2-datepicker';
import axios from 'axios';

export default {
  components: {
    Modal,
    DatePicker,
  },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    confirmText: {
      type: String,
      default: 'Select Date Range',
    },
    placeholder: {
      type: String,
      default: 'Select date range',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: true,
      file: '',
      showImport: true,
      showSync: false,
      selectedDate: null,
      selectedInbox: '',
      inboxDropdownEnabled: false,
      selectedAgents: [],
      selectedAgent: []
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
      inboxes: 'inboxes/getInboxes',
    }),
    csvUrl() {
      return '/downloads/import-contacts-sample.csv';
    },
  },
  mounted() {
    this.$track(CONTACTS_EVENTS.IMPORT_MODAL_OPEN);
  },
  methods: {
    async uploadFile() {
      const selectedAgents = this.selectedAgents.map(x => x.id);
      try {
        if (!this.file) return;
        const response=await this.$store.dispatch('contacts/import', { file: this.file, inboxId: this.selectedInbox, agentList: selectedAgents });
      
        this.onClose();
        if(response.message){
          this.showAlert(this.$t(response.message));
        }
        else{
          this.showAlert(this.$t('IMPORT_CONTACTS.SUCCESS_MESSAGE'));
        }
        this.$track(CONTACTS_EVENTS.IMPORT_SUCCESS);
      } catch (error) {
        this.showAlert(
          error.message || this.$t('IMPORT_CONTACTS.ERROR_MESSAGE')
        );
        this.$track(CONTACTS_EVENTS.IMPORT_FAILURE);
      }
    },  
    async fetchAttachedAgents() {
      try {
        const response = await this.$store.dispatch('inboxMembers/get', {
          inboxId: this.selectedInbox,
        });
        this.selectedAgents = [];
        const {
          data: { payload: inboxMembers },
        } = response;
        this.selectedAgent = inboxMembers;
      } catch (error) {
        //  Handle error
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    toggleElement(element) {
      if (element === 'import') {
        this.showImport = true;
        this.showSync = false;
      } else if (element === 'sync') {
        this.showSync = true;
        this.showImport = false;
      }
    },
    handleChange(value) {
      this.$emit('change', value);
    },
    async sync_contact() {
      const apiAccessToken = this.currentUser.access_token;
      if (!this.selectedDate || !Array.isArray(this.selectedDate) || this.selectedDate.length !== 2 || !this.selectedDate[0] || !this.selectedDate[1])  {
        console.log('Select Date range');
        this.showAlert(this.$t('Select Date range'));
        return false;
      }
      await axios
        .get(`/api/v1/accounts/${this.accountId}/contacts/contact_sync`, {
          params: {
            datetime: this.selectedDate,
          },
          headers: {
            api_access_token: apiAccessToken,
          },
        })
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            // Handle the response here
            console.log('Response:', response);
            this.showAlert(this.$t('Lead is synicing in background'));
            this.onClose();
            // Close the response
            return;
          } else {
            // Handle other status codes if needed
            console.error('Unexpected status code:', response.status);
          }
        })
        .catch(error => {
          // Handle errors
          console.error('Error:', error);
        });
    },
  },
};
</script>

<style scoped>
.custon_tab {
  display: inline-block;
  width: 49%;
  cursor: pointer;
  padding: 11px;
  font-weight: 600;
  margin: 1px;
}
.assign-title{
  padding-bottom: 16px;
}
</style>
