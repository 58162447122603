import Vue from 'vue';

export const set = (state, data) => {
  state.records = data;
};

export const setAgents = (state, data) => {
  console.log("Data",data);
  state.agents = data;
};

export const setStatus = (state, data) => {
  state.status_data = data;
};

export const setBoards = (state, data) => {
  state.boards = data;
};

export const setContact = (state, data) => {
  state.pipelines_contact = data;
};

export const setContacttoItems = (state, contacts) => {
  console.log("Contacts",contacts);
  console.log("Hello",state.records);
    state.records.forEach(list => {
      list.items = []; // Reset the items array for each list
    });
    // state.recods.items=[];
    console.log("Hello",state.records);
    contacts.forEach(contact => {
        
        const targetList = state.records.find(list => list.id === contact.status_id);

        // Log the target list for debugging

        if (targetList) {
            targetList.items.push(contact);
        } else {
            // console.log('List not found for status_id:', contact.status_id)
        }
    });
    console.log("State Records",state.records);
  
};

export const create = (state, data) => {
  state.records.push(data);
};

export const setSingleRecord = (state, data) => {
  const recordIndex = state.records.findIndex(record => record.id === data.id);
  if (recordIndex > -1) {
    state.records[recordIndex] = data;
  } else {
    create(state, data);
  }
};

export const update = (state, data) => {
  state.records.forEach((element, index) => {
    if (element.id === data.id) {
      Vue.set(state.records, index, data);
    }
  });
};

/* when you don't want to overwrite the whole object */
export const updateAttributes = (state, data) => {
  state.records.forEach((element, index) => {
    if (element.id === data.id) {
      Vue.set(state.records, index, { ...state.records[index], ...data });
    }
  });
};

export const updatePresence = (state, data) => {
  state.records.forEach((element, index) => {
    const availabilityStatus = data[element.id];
    Vue.set(
      state.records[index],
      'availability_status',
      availabilityStatus || 'offline'
    );
  });
};

export const updateSingleRecordPresence = (
  records,
  { id, availabilityStatus }
) => {
  const [selectedRecord] = records.filter(record => record.id === Number(id));
  if (selectedRecord) {
    Vue.set(selectedRecord, 'availability_status', availabilityStatus);
  }
};

export const destroy = (state, id) => {
  state.records = state.records.filter(record => record.id !== id);
};

export const updatedrag = (state,{oldIndex,newIndex}) => {
  // console.log("My Data",data);
  console.log("Moving element from position:", oldIndex, "to new position:", newIndex,state);
  if (oldIndex < 0 || oldIndex >= state.records.length || newIndex < 0 || newIndex >= state.records.length) {
    console.error("Invalid indices for moving records.");
    return;
  }

  const [movedElement] = state.records.splice(oldIndex, 1);
  state.records.splice(newIndex, 0, movedElement);
  console.log("State",state.records);
};


export const lazyloadingcontacts = (state, { contacts, page }) => {
  console.log("Contacts::::", contacts, page, contacts.length);

  if (contacts.length > 0) {
    contacts.forEach(contact => {
      // Find the target list where list.id matches contact.status_id
      const targetList = state.records.find(list => list.id === contact.status_id);
      console.log("Target List", targetList);

      // Ensure that targetList exists
      if (targetList) {
        // Assign the page value to the targetList
        targetList.page = page;

        // Assign true to contactpresent because we have contacts
        targetList.contactpresent = true;

        // Check if the contact already exists in the targetList.items array to ensure uniqueness
        const contactExists = targetList.items.some(item => item.id === contact.id);

        if (!contactExists) {
          // Add the contact to the target list's items if it's not already present
          targetList.items.push(contact);
        } else {
          console.log(`Duplicate contact found: ${contact.id}`);
        }
      } else {
        console.error('List not found for status_id:', contact.status_id);
      }
    });
  } else {
    // If no contacts are returned, update the corresponding list's contactpresent flag to false
    state.records.forEach(list => {
      // Check if a contact with the matching status_id exists
      const targetList = contacts.find(contact => contact.status_id === list.id);
      if (targetList) {
        list.contactpresent = false; // Set to false if no contacts for this specific list
        list.page = page; // Still update page even if no contacts
      }
    });
  }

  console.log("Updated State Records:", state.records);
};

