<template>
  <div
    ref="dropdownContainer"
    class="dropdown-container flex"
    @click="toggleDropdown"
  >
    <div class="dropdown-selected">
      {{
        selectedOption
          ? selectedOption.name.length > 15
            ? selectedOption.name.substring(0, 15) + '...'
            : selectedOption.name
          : placeholder
      }}
    </div>
    <div class="calendar">
      <svg
        fill="#ccc"
        width="15px"
        height="15px"
        viewBox="-6.5 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>dropdown</title>
        <path
          d="M18.813 11.406l-7.906 9.906c-0.75 0.906-1.906 0.906-2.625 0l-7.906-9.906c-0.75-0.938-0.375-1.656 0.781-1.656h16.875c1.188 0 1.531 0.719 0.781 1.656z"
        ></path>
      </svg>
    </div>
    <div v-if="isOpen" class="dropdown-options bg-white dark:bg-slate-900">
      <div
        v-for="state in status"
        :key="state.id"
        class="dropdown-option"
        :class="{
          'selected-option': selectedOption && selectedOption.id === state.id,
        }"
        @click="selectOption(state)"
        :title="state.name.length > 15 ? state.name : ''"
      >
      {{
          state.name.length > 15
            ? state.name.substring(0, 15) + '...'
            : state.name
        }}
      </div>
      <!-- <div class="dropdown-option add-more-option" @click="handleAddMore">
          {{ addMoreLabel }}
        </div> -->
    </div>
  </div>
</template>
      
      <script>
export default {
  props: {
    status: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Select an option',
    },
    open: {
      type: Boolean,
      required: false,
    },
  },
  watch: {
    open(newVal) {
      this.isOpen = newVal;
    },
  },
  data() {
    return {
      isOpen: false,
      selectedOption: null,
    };
  },
  methods: {
    toggleDropdown(event) {
      event.stopPropagation(); // Prevents toggleDropdown from closing immediately on open
      // this.isOpen = !this.isOpen;
      this.$emit('toggle');
    },
    selectOption(option) {
      this.selectedOption = option;
      this.isOpen = false;
      // console.log('Hello', this.isOpen);
      this.$emit('input', option.id);
      // this.closeDropdown();
      // Wait for Vue to finish processing `isOpen` change
      this.$nextTick(() => {
        this.closeDropdown();
      });
    },
    // handleAddMore(event) {
    //   event.stopPropagation(); // Prevents the dropdown from toggling
    //   this.isOpen = false;
    //   this.$emit('addMore', 'add-more');
    // },
    closeDropdown() {
      this.isOpen = false;
    },
    handleOutsideClick(event) {
      if (this.isOpen && !this.$refs.dropdownContainer.contains(event.target)) {
        this.closeDropdown();
      }
    },
    setDropdownNull(data){
      // console.log("Data:::",data);
      this.selectedOption=null;
      this.$emit('input',null);
    }
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
    bus.$on('dropdown-data-contact', this.setDropdownNull);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
};
</script>
     <style scoped>
.dropdown-container {
  position: relative;
  justify-content: space-between;
  width: 155px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
}

.dropdown-selected {
  font-size: 14px;
  /* color: #989898; */
}

.dropdown-options {
  position: absolute;
  box-shadow: 0px 3px 6px #00000029;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  z-index: 3;
  max-height: 168px;
  overflow: auto;
  font-family: 'Pacaembu Trial Light';
}
.dropdown-options::-webkit-scrollbar {
  display: none;
}

.dropdown-option {
  padding: 10px 20px;
  font-size: 14px;
  /* color: #b1b1b1; */
  color: grey;
  cursor: pointer;
  transition: background-color 0.2s;
  border-bottom: 1px solid #fff5f5;
}

.dropdown-option:hover {
  color: #f0f0f0;
  background-color: #007bff;
}

.add-more-option {
  /* color: #b1b1b1; */
  color: grey;
}

.dropdown-option:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.dropdown-option:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.selected-option {
  background-color: #007bff !important; /* Adjust color as needed */
  color: #fff;
}
.calendar {
  margin-top: 3px;
}
</style>
  