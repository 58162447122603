import { render, staticRenderFns } from "./AddCustomDate.vue?vue&type=template&id=28808310&scoped=true&"
import script from "./AddCustomDate.vue?vue&type=script&lang=js&"
export * from "./AddCustomDate.vue?vue&type=script&lang=js&"
import style0 from "./AddCustomDate.vue?vue&type=style&index=0&id=28808310&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28808310",
  null
  
)

export default component.exports