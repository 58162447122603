<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <woot-modal-header :header-title="$t('PIPELINES.CREATE_STATUS')" />
    <div class="remainder-table">
      <div class="remainder-modal">
        <div class="remainder-input date-time">
          <div class="date-time-input">
            <label>
              {{ $t('PIPELINES.EDIT_NAME') }}
              <span class="text-red-500">*</span>
              <woot-input
                class="status-name mt-2"
                :class="{ error: $v.name.$error }"
                :placeholder="$t('PIPELINES.ENTER_NAME')"
                :error="getLabelTitleErrorPipeline"
                v-model.trim="name"
                @input="$v.name.$touch"
              />
            </label>
            <label>
              {{ $t('PIPELINES.EDIT_DESCRIPTION') }}
              <!-- <woot-input
                class="status-description"
                :class="{ error: $v.description.$error }"
                :placeholder="$t('PIPELINES.ENTER_DESC')"
                v-model.trim="description"
                @input="$v.description.$touch"
              /> -->
              <div class="my-editor mt-2">
                <div class="editor-ui rounded-md" :class="{ error: $v.description.$error }">
                  <editor
                    v-model="description"
                    :placeholder="'Add Remark...'"
                    @input="$v.description.$touch"
                  />
                </div>
                <span v-if="$v.description.$error" class="text-red-500">
                  {{ getDescriptionError }}
                </span>
              </div>
            </label>
            <label>
              <div class="color-picker-wrapper mt-2">
                <p class="mb-2">{{ $t('PIPELINES.SELECT_COLOR') }}</p>
                <div  class="flex flex-wrap gap-1">
                  <div
                    v-for="(fixedColor, index) in colors"
                    :key="index"
                    @click="setColor(fixedColor)"
                    :style="{
                      backgroundColor: fixedColor,
                      width: '40px',
                      height: '40px',
                      cursor: 'pointer',
                      border: fixedColor === color ? '3px solid black' : '1px solid #ccc',
                      borderRadius: '50%',
                    }"
                    :title="fixedColor"
                  ></div>
                </div>
              </div>
            </label>
            <div class="flex justify-end items-center pb-6 pt-2 gap-2 w-full">
              <woot-submit-button
                :disabled="!isSubmitEnabled || uiFlags.isCreating"
                :loading="uiFlags.isCreating"
                :button-text="'Submit'"
                class="button-color"
                @click="createStatus"
              />
              <woot-button
                class="button clear cancel-btn"
                @click.prevent="onClose"
              >
                {{ $t('LABEL_MGMT.FORM.CANCEL') }}
              </woot-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </woot-modal>
</template>
  
  <script>
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import validations from './helpers/validations';
import pipelineMixin from './helpers/pipelineMixin.js';
import { getRandomColor } from 'dashboard/helper/labelColor';
import Editor from 'dashboard/components/widgets/WootWriter/Editor.vue'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    lists: {
      type: Number,
      required: true,
      default: 4,
    },
  },
  components: {
    Editor,
  },
  mixins: [alertMixin, pipelineMixin],
  data() {
    return {
      name: '',
      description: '',
      color: '#34922D', // Default selected color
      colors: [
        '#34922D',
        '#FFCC00',
        '#2D6FF5',
        '#B700AB',
        '#D62222',
        '#D9488C',
        '#FF7700',
        '#40518A',
        '#4300B7',
        '#009EB7',
      ], // Fixed list of colors
      id: '',
      draggable: false,
    };
  },
  validations,
  mounted() {
    this.color = getRandomColor();
  },
  computed: {
    ...mapGetters({
      uiFlags: 'pipelines/getUIFlags',
    }),
  },
  methods: {
    async createStatus() {
      const statusObj = {
        name: this.name,
        description: this.description,
        color: this.color,
        dragable: this.draggable,
        index: this.lists,
        account_id: this.$route.params.accountId,
      };
      console.log('Data', statusObj);
      const response = await this.$store.dispatch(
        'pipelines/createStatus',
        statusObj
      );
      this.onClose();
      this.getStatus();
      this.showAlert(response.data.message, { success: response.data.success });
      bus.$emit('dropdown-data', true);
      bus.$emit('reset-date-dropdown');
    },
    onClose() {
      this.$emit('close');
    },
    async getStatus() {
      this.$store.dispatch('pipelines/getStatus');
      const data = {
        key: 'state_id',
        value: '', // The state filter
        key1: 'name',
        value1: '', // The name filter
        key2: 'start_date',
        value2: '', // The start date filter
        key3: 'end_date',
        value3: '', // The end date filter
      };
      this.$store.dispatch('pipelines/FilterContact', data);
    },
    setColor(selectedColor) {
      this.color = selectedColor;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.remainder-table {
  margin: 10px 35px;
}

.remainder-modal {
  display: flex;
}

.remainder-input {
  width: 100%;
}
.remainder-text {
  width: 20%;
  font-size: 14px;
  font-weight: 500;
}
.remainder-text-select {
  padding: 5% 0px;
}
.input-textarea textarea {
  resize: none;
}
.date-time {
  display: flex;
  justify-content: space-between;
}
.date,
.time {
  margin: 0px 2%;
}
.remainder-time {
  display: flex;
  justify-content: inherit;
  width: 35%;
}
.date-time-input {
  width: 100%;
}
.footer {
  @apply mt-6 flex justify-end;
}
.remainder-time select {
  width: 66%;
}
.remainder-footer {
  flex-direction: inherit;
}
.labels-dropdown {
  display: flex;
  width: 45%;
  justify-content: space-evenly;
}
.automation-dropdown {
  display: flex;
  width: 45%;
  justify-content: space-evenly;
}
.label-automation {
  padding-top: 10px;
  justify-content: space-between;
}
.labels-text {
  width: 30%;
}
.label-input {
  width: 50%;
}
.automation-text {
  width: 55%;
}
.automation-input {
  width: 50%;
}
.overlay {
  position: fixed;
  margin: auto;
  left: 50vw;
  width: 250px;
}
.close-button {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}
.date-time-picker-modal {
  width: 90%;
  padding-top: 2%;
}
.cancel-btn {
  background-color: #fff;
  color: #40518a;
}
.cancel-btn:hover {
  background-color: #f9f9f9;
  color: #40518a;
}
.button-color {
  background-color: #40518a;
}
.editor-ui {
  border: 2px solid lightgrey;
  height: 200px;
  padding-left: 10px;
}
</style>
    