<template>
  <form
    class="contact--form w-full pt-6 px-8 pb-8"
    @submit.prevent="handleSubmit"
  >
    <div class="scrollable-form-wrapper">
      <div>
        <!-- <div class="w-full">
          <woot-avatar-uploader
            :label="$t('CONTACT_FORM.FORM.AVATAR.LABEL')"
            :src="avatarUrl"
            :username-avatar="name"
            :delete-avatar="!!avatarUrl"
            class="settings-item"
            @change="handleImageUpload"
            @onAvatarDelete="handleAvatarDelete"
          />
        </div> -->
        <div class="flex justify-center profile-picture">
          <div class="point" @click="openFileDialog">
            <img
              :src="profilePicture || 'default-avatar.png'"
              alt="Profile Picture"
            />
            <button type="button" class="edit-button">✏️</button>
          </div>

          <input
            type="file"
            ref="fileInput"
            @change="handleImageUpload"
            accept="image/*"
            style="display: none"
          />
          <div class="relative">
            <!-- <button
          v-if="profilePicture !== defaultImage"
          type="button"
          class="remove-button"
          @click="removeImage"
        >
          ❌ Remove Image
        </button> -->
        <!-- <woot-button
        v-if="profilePicture !== defaultImage"
          v-tooltip="'Remove profile'"
          title="'Remove profile'"
         icon="dismiss"
         class="remove-button cursor-pointer button clear button--only-icon secondary"
         @click="removeImage"
      /> -->
          </div>
        </div>
      </div>
      <div>
        <div class="w-full flex contact-input">
          <div class="w-[45%]">
            <label :class="{ error: $v.name.$error }">
         
      
         <div class="input-error-wrapper">
       <input
           v-model.trim="name"
           type="text"
           :placeholder="$t('CONTACT_FORM.FORM.NAME.PLACEHOLDER')"
          
           @input="validateLength('name', 50)"
             @keydown="restrictSpace('name', $event, 50)"
         />
         <span v-if="errors.name" class="message text-red-500">
           {{ errors.name }}
          </span>
         </div>
       </label>
          </div>
          <div class="w-[45%]">
            <label :class="{ error: $v.email.$error }">
              <input
                v-model.trim="email"
                type="text"
                :placeholder="$t('CONTACT_FORM.FORM.EMAIL_ADDRESS.PLACEHOLDER')"
                @input="$v.email.$touch"
              />
              <span v-if="$v.email.$error" class="message">
                {{ $t('CONTACT_FORM.FORM.EMAIL_ADDRESS.ERROR') }}
              </span>
            </label>
          </div>
        </div>
      </div>

      <div>
        <div class="w-full flex contact-input">
          <div class="w-[45%]">
            <label
              :class="{
                error: isPhoneNumberNotValid,
              }"
            >
              <woot-phone-input
                v-model="phoneNumber"
                :value="phoneNumber"
                :error="isPhoneNumberNotValid"
                @input="onPhoneNumberInputChange"
                @blur="$v.phoneNumber.$touch"
                @setCode="setPhoneCode"
              />
              <span v-if="isPhoneNumberNotValid" class="message">
                {{ phoneNumberError }}
              </span>
            </label>
          </div>
          <div class="w-[45%]">
            <woot-input
              class="w-full"
              v-model.trim="companyName"
              :placeholder="$t('CONTACT_FORM.FORM.COMPANY_NAME.PLACEHOLDER')"
            />
          </div>
        </div>
      </div>
      <div>
        <div class="w-full flex contact-input">
          <div class="w-[45%]">
            <multiselect
              v-model="country"
              track-by="id"
              label="name"
              :placeholder="$t('CONTACT_FORM.FORM.COUNTRY.PLACEHOLDER')"
              selected-label
              :select-label="$t('CONTACT_FORM.FORM.COUNTRY.SELECT_PLACEHOLDER')"
              :deselect-label="$t('CONTACT_FORM.FORM.COUNTRY.REMOVE')"
              :custom-label="countryNameWithCode"
              :max-height="160"
              :options="countries"
              :allow-empty="true"
              :option-height="104"
            />
          </div>
          <div class="w-[45%]">
            <woot-input
              v-model="city"
              class="w-full"
              :placeholder="$t('CONTACT_FORM.FORM.CITY.PLACEHOLDER')"
            />
          </div>
        </div>
      </div>

      <div class="w-full">
        <label :class="{ error: $v.description.$error }">
     
     <div class="input-error-wrapper">
      <textarea
        v-model.trim="description"
        type="text"
        :placeholder="$t('CONTACT_FORM.FORM.BIO.PLACEHOLDER')"
          @input="validateLength('description', 200)"
           @keydown="restrictSpace('description', $event, 200)"
      />
      <span v-if="errors.description" class="message text-red-500">
      {{ errors.description}}
    </span>
     </div>
    </label>
      </div>
      <div class="grey-color">Social Profile</div>
      <div class="w-full">
        <div>
          <!-- <div class="flex contact-input">
            <div class="w-[45%]">
              <div
                v-for="socialProfile in firstColumn"
                :key="socialProfile.key"
                class="mb-4"
              >
                <input
                  v-model="socialProfileUserNames[socialProfile.key]"
                  class="input-group-field rounded-md"
                  type="text"
                  :placeholder="socialProfile.prefixURL"
                  @input="validateSocialProfile(socialProfile.key)"
                />
                <span
                  v-if="
                    !isValidLink[socialProfile.key] &&
                    socialProfileUserNames[socialProfile.key]
                  "
                  class="invalid-link-message"
                >
                  
                  {{ $t('CONTACT_FORM.FORM.SOCIAL_PROFILES.ERROR') }}
                </span>
              </div>
            </div>

            <div class="w-[45%]">
              <div
                v-for="socialProfile in secondColumn"
                :key="socialProfile.key"
                class="mb-4"
              >
                <input
                  v-model="socialProfileUserNames[socialProfile.key]"
                  class="input-group-field rounded-md"
                  type="text"
                  :placeholder="socialProfile.prefixURL"
                  @input="validateSocialProfile(socialProfile.key)"
                />
                <span
                  v-if="
                    !isValidLink[socialProfile.key] &&
                    socialProfileUserNames[socialProfile.key]
                  "
                  class="invalid-link-message"
                >
                  
                  {{ $t('CONTACT_FORM.FORM.SOCIAL_PROFILES.ERROR') }}
                </span>
              </div>
            </div>
          </div> -->

          <div class="contact-input">
            <div>
              <div
                v-for="socialProfile in firstColumn"
                :key="socialProfile.key"
                class="mb-4 social-profile-row"
              >
                <!-- Fixed Prefix -->
                <div class="prefix-url">
                  {{ socialProfile.prefixURL }}
                </div>

                <!-- Editable Username -->
                <input
                  v-model="socialProfileUserNames[socialProfile.key]"
                  class="input-group-field rounded-md"
                  type="text"
                  
                  placeholder="Enter your username"
                />
              </div>
            </div>

            <div>
              <div
                v-for="socialProfile in secondColumn"
                :key="socialProfile.key"
                class="mb-4 social-profile-row"
              >
                <!-- Prefix URL + Editable Input -->
                <div class="flex items-center w-full">
                  <span class="prefix-url">
                    {{ socialProfile.prefixURL }}
                  </span>
                  <input
                    v-model="socialProfileUserNames[socialProfile.key]"
                    class="input-group-field"
                    type="text"
                    placeholder="Enter your username"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="grey-color">
          {{ $t('CONTACT_FORM.FORM.REMARKS.LABEL') }}
        </div>
        <textarea
          v-model.trim="remark"
          class="w-full"
          type="text"
          :placeholder="$t('CONTACT_FORM.FORM.REMARKS.PLACEHOLDER')"
            @input="validateLength('remark', 200)"
             @keydown="restrictSpace('remark', $event, 200)"
        />
        <span v-if="errors.remark" class="message text-red-500">
           {{ errors.remark }}
         </span>
      </div>
    </div>
    <div class="flex flex-row gap-2 py-2 px-0 w-full">
      <div class="w-full flex justify-center gap-1">
        <woot-submit-button
          :loading="loading"
          :disabled="
            name.length == 0 ||
            email.length == 0 ||
            phoneNumber.length == 0 ||
            !areAllSocialLinksValid ||
            isSubmitDisabled
          "
          class="button-color"
          :button-text="$t('CONTACT_FORM.FORM.SUBMIT')"
        />
        <button class="button clear color" @click.prevent="onCancel">
          {{ $t('CONTACT_FORM.FORM.CANCEL') }}
        </button>
      </div>
    </div>
  </form>
</template>
  
  <script>
import alertMixin from 'shared/mixins/alertMixin';
import {
  DuplicateContactException,
  ExceptionWithMessage,
} from 'shared/helpers/CustomErrors';
import { required, email } from 'vuelidate/lib/validators';
import countries from 'shared/constants/countries.js';
import { isPhoneNumberValid } from 'shared/helpers/Validators';
import parsePhoneNumber from 'libphonenumber-js';

export default {
  mixins: [alertMixin],
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    inProgress: {
      type: Boolean,
      default: false,
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
    extraValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countries: countries,
      companyName: '',
      description: '',
      email: '',
      name: '',
      phoneNumber: '',
      remark: '',
      loading: false,
      activeDialCode: '',
      avatarFile: null,
      initialFormData: null,
      avatarUrl: '',
      country: {
        id: '',
        name: '',
      },
      city: '',
      errors: {
        name: '',
        email: '',
        companyName: '',
        city: '',
        remark: '',
        description: '',
        socialProfiles: {
          facebook: '',
          twitter: '',
          linkedin: '',
          github: '',
        },
      },
      socialProfileUserNames: {
        facebook: '',
        twitter: '',
        linkedin: '',
        github: '',
      },
      socialProfileKeys: [
        { key: 'facebook', prefixURL: 'https://facebook.com/' },
        { key: 'twitter', prefixURL: 'https://twitter.com/' },
        { key: 'linkedin', prefixURL: 'https://linkedin.com/' },
        { key: 'github', prefixURL: 'https://github.com/' },
      ],
      profilePicture:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpCKq1XnPYYDaUIlwlsvmLPZ-9-rdK28RToA&usqp=CAU',
      defaultImage:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpCKq1XnPYYDaUIlwlsvmLPZ-9-rdK28RToA&usqp=CAU',

      isValidLink: {
        facebook: true,
        twitter: true,
        linkedin: true,
        github: true,
      },
    };
  },
  validations: {
    name: {
      required,
    },
    description: {},
    email: {
      email,
    },
    companyName: {},
    phoneNumber: {},
    bio: {},
  },
  computed: {
    parsePhoneNumber() {
      return parsePhoneNumber(this.phoneNumber);
    },
    isPhoneNumberNotValid() {
      if (this.phoneNumber !== '') {
        return (
          !isPhoneNumberValid(this.phoneNumber, this.activeDialCode) ||
          (this.phoneNumber !== '' ? this.activeDialCode === '' : false)
        );
      }
      return false;
    },

    phoneNumberError() {
      if (this.activeDialCode === '') {
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.DIAL_CODE_ERROR');
      }
      if (!isPhoneNumberValid(this.phoneNumber, this.activeDialCode)) {
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.ERROR');
      }
      return '';
    },
    setPhoneNumber() {
      if (this.parsePhoneNumber && this.parsePhoneNumber.countryCallingCode) {
        return this.phoneNumber;
      }
      if (this.phoneNumber === '' && this.activeDialCode !== '') {
        return '';
      }
      return this.activeDialCode
        ? `${this.activeDialCode}${this.phoneNumber}`
        : '';
    },
    firstColumn() {
      return this.socialProfileKeys.slice(0, 2); // First two profiles
    },
    secondColumn() {
      return this.socialProfileKeys.slice(2, 4); // Last two profiles
    },
    areAllSocialLinksValid() {
      return Object.values(this.isValidLink).every(isValid => isValid);
    },
    isSubmitDisabled() {
      return !this.isFormChanged() || !this.name || !this.email || !this.phoneNumber || this.$v.$invalid || this.isPhoneNumberNotValid;
    },
  },
  watch: {
    contact() {
      this.setContactObject();
    },
  },
  mounted() {
    this.setContactObject();
    this.setDialCode();
    this.captureInitialState();
  },
  methods: {
    validateLength(field, maxLength) {
      if (this[field].length > maxLength) {
        // Trim the input to the max length including spaces
        this[field] = this[field].slice(0, maxLength);
        this.$set(this.errors, field, `Maximum character should be ${maxLength}.`);
      } else {
        this.$set(this.errors, field, ''); // Clear the error when valid
      }
    },
    restrictSpace(field, event, maxLength) {
      // Prevent space bar if character limit is reached
      if (event.key === ' ' && this[field].length >= maxLength) {
        event.preventDefault(); // Block space from being added
      }
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('success');
    },
    countryNameWithCode({ name, id }) {
      if (!id) return name;
      if (!name && !id) return '';
      return `${name} (${id})`;
    },
    setDialCode() {
      if (
        this.phoneNumber !== '' &&
        this.parsePhoneNumber &&
        this.parsePhoneNumber.countryCallingCode
      ) {
        const dialCode = this.parsePhoneNumber.countryCallingCode;
        this.activeDialCode = `+${dialCode}`;
      } else {
        this.activeDialCode = '';
      }
    },
    setContactObject() {
      const {
        email: emailAddress,
        phone_number: phoneNumber,
        name,
      } = this.contact;
      const additionalAttributes = this.contact.additional_attributes || {};

      this.name = name || '';
      this.email = emailAddress || '';
      this.phoneNumber = phoneNumber || '';
      this.remark = additionalAttributes.remark || '';
      this.companyName = additionalAttributes.company_name || '';
      this.country = {
        id: additionalAttributes.country_code || '',
        name:
          additionalAttributes.country ||
          this.$t('CONTACT_FORM.FORM.COUNTRY.SELECT_COUNTRY'),
      };
      this.city = additionalAttributes.city || '';
      this.description = additionalAttributes.description || '';
      this.avatarUrl = this.contact.thumbnail || '';
      this.profilePicture = this.contact.thumbnail || this.defaultImage;
      const {
        social_profiles: socialProfiles = {},
        screen_name: twitterScreenName,
      } = additionalAttributes;
      this.socialProfileUserNames = {
        twitter: socialProfiles.twitter || twitterScreenName || '',
        facebook: socialProfiles.facebook || '',
        linkedin: socialProfiles.linkedin || '',
        github: socialProfiles.github || '',
        instagram: socialProfiles.instagram || '',
      };
    },
    getContactObject() {
      if (this.country === null) {
        this.country = {
          id: '',
          name: '',
        };
      }
      const contactObject = {
        id: this.contact.id,
        name: this.name,
        email: this.email,
        phone_number: this.setPhoneNumber,
        additional_attributes: {
          ...this.contact.additional_attributes,
          description: this.description,
          company_name: this.companyName,
          country_code: this.country.id,
          country:
            this.country.name ===
            this.$t('CONTACT_FORM.FORM.COUNTRY.SELECT_COUNTRY')
              ? ''
              : this.country.name,
          city: this.city,
          social_profiles: this.socialProfileUserNames,
          remark: this.remark,
        },
      };
      if (this.avatarFile) {
        contactObject.avatar = this.avatarFile;
        contactObject.isFormData = true;
      }
      return contactObject;
    },
    onPhoneNumberInputChange(value, code) {
      this.activeDialCode = code;
    },
    setPhoneCode(code) {
      if (this.phoneNumber !== '' && this.parsePhoneNumber) {
        const dialCode = this.parsePhoneNumber.countryCallingCode;
        if (dialCode === code) {
          return;
        }
        this.activeDialCode = `+${dialCode}`;
        const newPhoneNumber = this.phoneNumber.replace(
          `+${dialCode}`,
          `${code}`
        );
        this.phoneNumber = newPhoneNumber;
      } else {
        this.activeDialCode = code;
      }
    },
    async handleSubmit() {
      this.loading = true;
      this.$v.$touch();
      if (this.$v.$invalid || this.isPhoneNumberNotValid) {
        return;
      }
      try {
        const response = await this.onSubmit(this.getContactObject());
        this.onSuccess();
        this.showAlert(
          this.extraValue
            ? 'Contact Already Exists'
            :  this.$t('CONTACT_FORM.UPDATED_MESSAGE')
        );
        // this.handleAvatarDelete();
      } catch (error) {
        if (error instanceof DuplicateContactException) {
          if (error.data.includes('email')) {
            this.showAlert(
              this.$t('CONTACT_FORM.FORM.EMAIL_ADDRESS.DUPLICATE')
            );
          } else if (error.data.includes('phone_number')) {
            this.showAlert(this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.DUPLICATE'));
          }
        } else if (error instanceof ExceptionWithMessage) {
          this.showAlert(error.data);
        } else {
          this.showAlert(this.$t('CONTACT_FORM.ERROR_MESSAGE'));
        }
      } finally {
        this.loading = false;
      }
      bus.$emit('dropdown-data-contact', true);
    },
    // handleImageUpload({ file, url }) {
    //   this.avatarFile = file;
    //   this.avatarUrl = url;
    // },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = e => {
          this.avatarFile = file; // Store the file reference
          this.avatarUrl = e.target.result; // Store the data URL
          this.profilePicture = e.target.result; // Update profile picture for display
        };
        reader.readAsDataURL(file); // Convert the file to a data URL
      }
    },
    async handleAvatarDelete() {
      try {
        if (this.contact && this.contact.id) {
          await this.$store.dispatch('contacts/deleteAvatar', this.contact.id);
          this.showAlert(
            this.$t('CONTACT_FORM.DELETE_AVATAR.API.SUCCESS_MESSAGE')
          );
        }
        this.avatarFile = null;
        this.profilePicture = null;
        this.avatarUrl = '';
        this.activeDialCode = '';
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('CONTACT_FORM.DELETE_AVATAR.API.ERROR_MESSAGE')
        );
      }
    },
    validateSocialProfile(key) {
      const url = this.socialProfileUserNames[key];
      if (!url) {
        // If the field is empty, consider it valid
        this.isValidLink[key] = true;
      } else {
        // Validate only if there's input
        const urlPattern =
          /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
        this.isValidLink[key] = urlPattern.test(url);
      }
    },
    removeImage() {
      this.$refs.fileInput.value = ''; // Clear the file input
      // this.handleAvatarDelete();
      this.profilePicture = this.defaultImage; // Reset to default image
    },
    captureInitialState() {
      this.initialFormData = JSON.stringify(this.getContactObject());
    },
    isFormChanged() {
      const currentFormData = JSON.stringify(this.getContactObject());
      return currentFormData !== this.initialFormData;
    },
  },
};
</script>
  
  <style scoped lang="scss">
.input-group-label {
  @apply text-sm bg-slate-50 dark:bg-slate-700 text-slate-800 dark:text-slate-100 border-slate-200 dark:border-slate-600;
}

::v-deep {
  .multiselect .multiselect__tags .multiselect__single {
    @apply pl-0;
    color: #989898;
  }
}
.contact-input {
  justify-content: space-between;
}
.button-color {
  background-color: #40518a;
}
.color {
  color: #40518a;
}
.point {
  cursor: pointer;
  display: inline-grid;
}
.scrollable-form-wrapper {
  // height: 282px;
  // overflow-y: auto;
  height: 428px;
  overflow-y: scroll;
  scrollbar-width: none;
}
.contact--form {
  height: 100%;
}
.profile-picture {
  text-align: center;
  margin: 20px 0;
}
.profile-picture img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.edit-button {
  display: block;
  margin: -1px auto;
  background: yellow;
  border: none;
  cursor: pointer;
  font-size: x-small;
  border-radius: 10px;
}

.grey-color {
  color: #989898;
}
.scrollable-form-content {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  padding-bottom: 16px;
}
.scrollable-form-content::-webkit-scrollbar {
  display: none;
}
.grey-color ::v-deep input {
  color: #989898 !important;
}
.invalid-link-message {
  color: rgb(241, 85, 90);
  font-size: 0.8em;
}
.input-group-field.error {
  border-color: rgb(241, 85, 90);
}
.social-profile-row {
  display: flex;
  align-items: center;
}

.prefix-url {
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  color: #374151; /* Adjust based on theme */
  background-color: #f3f4f6; /* Light gray */
  padding: 8px 12px;
  border: 1px solid #d1d5db; /* Light border */
  border-radius: 4px 0 0 4px; /* Rounded on the left */
}

.input-group-field {
  flex: 1;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #d1d5db; /* Same border color */
  border-left: none; /* Remove border overlap */
  border-radius: 0 4px 4px 0; /* Rounded on the right */
  background-color: #ffffff; /* White background */
}
.social-profile-row:last-child {
  margin-bottom: 24px; /* Add space after the last row */
}
.input-error-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2px; /* Adjust to reduce spacing, use 0 if needed */
}
.message {
  font-size: 12px;
  margin-top: -13px;
}
.remove-button{
  position: absolute !important;
  top: -20px !important;
  padding-top: 0px !important;
  right: -42px !important;
}
</style>
  